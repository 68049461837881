const env = {
    'api': (window.X_API_URL || process.env.REACT_APP_API_URL),
    'is_development': (window.X_IS_DEVELOPMENT === "true" || process.env.REACT_APP_IS_DEVELOPMENT === "true" || false),
    'dashboard_refresh': (window.X_DASHBOARD_REFRESH || process.env.REACT_APP_DASHBOARD_REFRESH),
    'organization_name': (window.X_ORGANIZATION_NAME || process.env.REACT_APP_ORGANIZATION_NAME),
    'footer_name': (window.X_FOOTER_NAME || process.env.REACT_APP_FOOTER_NAME),
    'colors': (JSON.parse(window.X_COLORS || process.env.REACT_APP_COLORS)),
    'header_color': (window.X_HEADER_COLOR || process.env.REACT_APP_HEADER_COLOR)
};

export default env;