import axios from "axios";

import env from "../env/src_config";
import { isNull } from "../izUtils";
import { axiosError } from "./response";

export const saveService = async (headers, id, passedInputs, lsTechicians, save, save_draft, serviceFiles) => {
    let payload = { save, save_draft };

    if (!isNull(passedInputs)) {
        // Get data from inputs
        const keys = Object.keys(passedInputs);
        payload.data = {};
        for (let i = 0; i < keys.length; i++) {
            if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                payload.data[keys[i]] = passedInputs[keys[i]].value;
            } else {
                payload.data[keys[i]] = "";
            }
        }

        // Get data from localStorage
        let selectedTechnicians = JSON.parse(localStorage.getItem(lsTechicians))
        if (!isNull(selectedTechnicians)) {
            if (isNull(payload.data)) payload.data = {};
            payload.data.technicians = selectedTechnicians.map(technician => {
                return {
                    id: technician.id,
                    from: technician.from,
                    to: technician.to,
                    programming_work: technician.programming_work,
                    remote_work: technician.remote_work,
                }
            })
        }

        if (!isNull(serviceFiles)) {
            if (isNull(payload.data)) payload.data = {};
            payload.data.files = serviceFiles.map(file => file.id);
        }
    }

    const serviceResponse = await axios.post(env.api + '/api/service/create/' + id, payload, {headers}).then(response => {
        return response.data
    }).catch(error => {
        axiosError(error);
    });

    return serviceResponse;
}