import React, { useState, useEffect, useMemo } from 'react'
import { t } from 'i18next'
import DataTable from 'react-data-table-component'
import { FormGroup, Label, Input, Button, CardBody, Card, CardFooter } from 'reactstrap'
import Modal from 'react-modal'
import moment from 'moment'

import { isNull, customStyles, paginationComponentOptions, modalStyles, displayDate } from '../../../izUtils'
import { FilterComponentText, FilterComponentSelect } from './FilterComponent'

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const TableField = ({data, saveToLS, showErrors, updateData}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [filterDepartment, setFilterDepartment] = useState("");
    const [filterType, setFilterType] = useState("technician");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSelectedtechnician, setModalSelectedTechnician] = useState(null);

    useEffect(() => {
        let selected = [];
        const tableTechnicians = data.values.map(row => {
            if (!isNull(data.value) && data.value.length !== 0) {
                if (data.value.indexOf(row.id) !== -1) selected.push(row.id)
            }

            return {...row, name: isNull(row.title) ? row.name : row.title,
                // id: row.id,
                // totalAssignments: isNull(row.total_assignments) ? row.tasks : row.total_assignments,
                // tasks: row.tasks,
                // events: row.events,
            }
        })

        setSelectedRows(selected)
        localStorage.setItem(saveToLS, JSON.stringify(selected))
        setTableData(tableTechnicians)

    }, [JSON.stringify(data.values)]) // eslint-disable-line react-hooks/exhaustive-deps

    // Create table columns
    const tableColumns = useMemo(() => {
        let columns = [
            { name: t('table.name'), selector: row => row.name, sortable: true },
            {
                name: t('table.tasksPerDay'),
                selector: row => row.total_assignments,
                sortable: true,
                cell: row => (row.total_assignments > 0) ? <div onClick={() => handleModalButton(row)}><b>{row.total_assignments}</b></div> : row.total_assignments
            },
            {
                name: t('table.department'),
                selector: row => row.department,
                sortable: true,
                cell: row => {
                    return (
                        <div>
                            {row.department.map((dep, index) => (
                                <div key={'dep'+index}>{t(`table.techDepartment.${dep}`)}</div>
                            ))}
                        </div>
                    )
                }
            },
        ]

        if (!data.disabled) {
            columns.unshift(
                {
                    name: '',
                    width: '50px',
                    cell: row => {
                        let isSelected = false;
                        if (selectedRows.indexOf(row.id) !== -1) isSelected = true;
                        return (
                            <div className="custom-control custom-checkbox form-check">
                                <div className="form-checkbox-group">
                                    <Input
                                        className={"custom-control-input"}
                                        id={row.id + '-' + saveToLS}
                                        type="checkbox"
                                        onChange={() => handleRowSelected(row)} checked={isSelected}
                                    />
                                    <Label className="checkmark secondary" htmlFor={row.id + '-' + saveToLS}></Label>
                                </div>
                            </div>
                        )
                    }
                },
            )
        }

        return columns;
    }, [selectedRows, data.disabled]) // eslint-disable-line react-hooks/exhaustive-deps

    // Row handlers
    const handleRowSelected = (row) => {
        let clonedSelectedRows = [...selectedRows];
        if (clonedSelectedRows.indexOf(row.id) === -1) {
            clonedSelectedRows.push(row.id)
        } else {
            let filtered = clonedSelectedRows.filter(selected => selected !== row.id)
            clonedSelectedRows = filtered;
        }
        setSelectedRows(clonedSelectedRows)
        localStorage.setItem(saveToLS, JSON.stringify(clonedSelectedRows))
        updateData()
    }

    const handleModalButton = (row) => {
        if (!isNull(row) && !isNull(row.events)) {
            setModalSelectedTechnician(row)
            setModalOpen(true)
        } else {
            setModalSelectedTechnician(null)
            setModalOpen(false)
        }
    }
    // End row handlers

    const subHeaderComponent = useMemo(() => {
        const handleClear = (type) => {
            if (type === 'department') {
                setFilterDepartment("");
            } else if (type === 'type') {
                setFilterType("");
            } else if (type === 'text') {
                setFilterText("");
            }
        };

        const optionsDepartment = [
            { id: 'it_video', title: t('table.techDepartment.it_video') },
            { id: 'servicer', title: t('table.techDepartment.servicer') },
            { id: 'installer', title: t('table.techDepartment.installer') },
            { id: 'electrician', title: t('table.techDepartment.electrician') },
            { id: 'external_contractor', title: t('table.techDepartment.external_contractor') },
        ]

        const optionsType = [
            { id: 'technician', title: t('table.userType.technician') },
            { id: 'admin', title: t('table.userType.admin') },
        ]

        return (
            <div className='w-100 d-flex flex-wrap justify-content-between'>
                <FilterComponentSelect
                    options={optionsType}
                    onFilter={e => setFilterType(e.target.value)}
                    onClear={() => handleClear('type')}
                    filterValue={filterType}
                    type="type"
                />
                <FilterComponentSelect
                    options={optionsDepartment}
                    onFilter={e => setFilterDepartment(e.target.value)}
                    onClear={() => handleClear('department')}
                    filterValue={filterDepartment}
                    type="department"
                />
                <FilterComponentText
                    onFilter={e => setFilterText(e.target.value)}
                    onClear={() => handleClear('text')}
                    filterValue={filterText}
                />
            </div>
        );
      }, [filterText, filterDepartment, filterType]);

    let filteredItems = useMemo(() => {
        let filteredTableData = tableData.filter(item => {
            return (item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
        });

        filteredTableData = filteredTableData.filter(item => {
            return (filterDepartment === "" || item.department.indexOf(filterDepartment) !== -1)
        });

        filteredTableData = filteredTableData.filter(item => {
            return (filterType === "" || item.type === filterType)
        });

        return filteredTableData;
    }, [tableData, filterText, filterDepartment, filterType])

    if (data.display) {
        return (
            <FormGroup className={"table-with-filters"}>
                <Label>{"Tip uporabnika"/*data.title*/ + (data.mandatory ? " *" : "")}</Label>
                {tableData.length !== 0 ?
                    <DataTable
                        columns={tableColumns}
                        data={filteredItems}
                        pagination
                        paginationComponentOptions={paginationComponentOptions(t)}
                        customStyles={customStyles}
                        subHeader
                        subHeaderComponent={subHeaderComponent}
                    />
                    :
                    <div>{t('table.noTechnicians')}</div>
                }
                {!isNull(showErrors) && showErrors && !isNull(data.errors) && data.errors.length !== 0 &&
                    data.errors.map((error, index) => (
                        <div key={'material-error-'+index} style={{ color: "red", marginBottom: "10px" }}>{error}</div>
                    ))
                }
                <Modal
                    isOpen={modalOpen}
                    onRequestClose={() => handleModalButton()}
                    style={modalStyles}
                    contentLabel="Security System Component Modal"
                >
                    {!isNull(modalSelectedtechnician) &&
                        <Card className="ribbon-wrapper" style={{ marginBottom: '0px' }}>
                            <CardBody>
                                <div className="ribbon ribbon-clip ribbon-primary">{modalSelectedtechnician.name}</div>
                                <div>
                                    {modalSelectedtechnician.tasks.length !== 0 &&
                                    <div className='mb-4'>
                                            <b>{t('tasks')}</b>
                                            {modalSelectedtechnician.tasks.map(task => (
                                                <div key={'task'+task.id} className="my-1 pt-1" style={{ borderTop: '1px solid #999' }}>
                                                    {task.client.client + ', ' + task.facility.name}
                                                    <br />
                                                    {displayDate(moment.utc(task.service_from)) + ' - ' + displayDate(moment.utc(task.service_to))}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {modalSelectedtechnician.events.length !== 0 &&
                                        <div className='mb-4'>
                                            <b>{t('events')}</b>
                                            {modalSelectedtechnician.events.map(event => (
                                                <div key={'event'+event.id} className="my-1 pt-1" style={{ borderTop: '1px solid #999' }}>
                                                    {event.client.client + ', ' + event.facility.name}
                                                    <br />
                                                    {displayDate(moment.utc(event.from)) + ' - ' + displayDate(moment.utc(event.to))}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </CardBody>
                            <CardFooter>
                                <div className='text-start'>
                                    <Button className='primary' onClick={() => handleModalButton()}>{t('close')}</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    }
                </Modal>
      </FormGroup>
        )
    } else {
        return null;
    }
}

export default TableField