import React, {useState, useEffect, useMemo} from 'react'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'
import DataTable from 'react-data-table-component'
import { FormGroup, Label, Input, Button } from 'reactstrap'
import Swal from 'sweetalert2'

import TimeInput from '../../form/TimeInput'
import { tokenState } from '../../../recoil/recoil'
import { isNull, customStyles, paginationComponentOptions, number_format } from '../../../izUtils'
import { checkHistory } from '../../../helpers/checkHistory'
import DisplayHistory from '../../../helpers/DisplayHistory'

const TableField = ({data, saveToLS, showErrors, getData, type, history, quotationPricing}) => {
    const token = useRecoilValue(tokenState);
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        const tableTechnicians = data.values.map(row => {
            let techData = {...row};

            // Get more data from data.value
            if (!isNull(data.value) && data.value.length !== 0) {
                data.value.forEach(val => {
                    if (!isNull(val) && val.id === techData.id) {
                        techData = {...techData, ...val, }
                    }
                })
            }

            return techData;
        })

        if (!isNull(data.value) && data.value.length !== 0) {
            setSelectedRows(data.value)
            localStorage.setItem(saveToLS, JSON.stringify(data.value))
        } else {
            localStorage.setItem(saveToLS, JSON.stringify([]))
        }

        setTableData(tableTechnicians)
    }, [JSON.stringify(data.value)]) // eslint-disable-line react-hooks/exhaustive-deps

    let hasHistory = false;
    if (!isNull(history)) {
        hasHistory = checkHistory(data, history);
    }

    // Create table columns
    const tableColumns = useMemo(() => {
        let columns = [
            { name: t('table.name'), selector: row => row.title, sortable: true },
        ];

        if (type !== 'sign' || (type === 'sign' && !quotationPricing)) {
            columns.push(
                {
                    name: t('table.workLog'),
                    selector: row => row.work_log.total,
                    sortable: true,
                    cell: row => {
                        let workLog = []
                        if (!isNull(row.work_log)) {
                            if (type === 'public') {
                                return number_format(row.work_log.total, 2, ',', '.');
                            } else {
                                for (const log in row.work_log) {
                                    if (!isNull(row.work_log[log]) && row.work_log[log] > 0) {
                                        workLog.push(
                                            <div key={'work_log-'+log} style={log === 'total' ? { fontWeight: '600', marginTop: '3px', paddingTop: '3px', borderTop: '1px solid #000' }: {} }>
                                                {t(`${log}`) + ": " + number_format(row.work_log[log], 2, ',', '.')}
                                            </div>
                                        )
                                    }
                                }
                            }
                        }
                        return <div>{workLog}</div>
                    }
                }
            )
        }

        if (type !== 'sign' && type !== 'public') {
            columns.push(
                {
                    name: t('table.programming_work'),
                    cell: row => {
                        if (row.demanding_work) {
                            let disabled = true;
                            if (!data.disabled) {
                                selectedRows.forEach(selectedRow => {
                                    if (selectedRow.id === row.id) disabled = false
                                });
                            }

                            let changed = false;
                            if (!isNull(history) && hasHistory) {
                                data.value?.forEach(val => {
                                    if (val.id === row.id) {
                                        if (history.field.value.indexOf(val.id) !== -1) {
                                            const historyValue = history.field.values.find(value => value.id === row.id);
                                            changed = historyValue["programming_work"] !== row["programming_work"];
                                        }
                                    }
                                })
                            }

                            return (
                                <div className="custom-control custom-checkbox form-check">
                                    <div className="form-checkbox-group">
                                        <Input
                                            className={"custom-control-input"}
                                            id={row.id + '-programming_work-' + saveToLS}
                                            type="checkbox"
                                            onChange={() => handleRowBool(row, 'programming_work')}
                                            checked={ isNull(row.programming_work) ? false : row.programming_work }
                                            disabled={disabled}
                                            />
                                        <Label className={"checkmark " + (changed ? "danger" : "secondary")} htmlFor={row.id + '-programming_work-' + saveToLS}></Label>
                                    </div>
                                </div>
                            )
                        } else {
                            return null;
                        }
                    }
                },
                {
                    name: t('table.remote_work'),
                    cell: row => {
                        if (row.demanding_work) {
                            let disabled = true;
                            if (!data.disabled) {
                                selectedRows.forEach(selectedRow => {
                                    if (selectedRow.id === row.id) disabled = false
                                });
                            }

                            let changed = false;
                            if (!isNull(history) && hasHistory) {
                                data.value?.forEach(val => {
                                    if (val.id === row.id) {
                                        if (history.field.value.indexOf(val.id) !== -1) {
                                            const historyValue = history.field.values.find(value => value.id === row.id);
                                            changed = historyValue["remote_work"] !== row["remote_work"];
                                        }
                                    }
                                })
                            }

                            return (
                                <div className="custom-control custom-checkbox form-check">
                                    <div className="form-checkbox-group">
                                        <Input
                                            className={"custom-control-input"}
                                            id={row.id + '-remote_work-' + saveToLS}
                                            type="checkbox"
                                            onChange={() => handleRowBool(row, 'remote_work')}
                                            checked={ isNull(row.remote_work) ? false : row.remote_work }
                                            disabled={disabled}
                                            />
                                        <Label className={"checkmark " + (changed ? "danger" : "secondary")} htmlFor={row.id + '-remote_work-' + saveToLS}></Label>
                                    </div>
                                </div>
                            )
                        } else {
                            return null;
                        }
                    }
                },
                {
                    name: t('table.from'),
                    selector: row => row.from,
                    cell: row => {
                        if (data.disabled) {
                            return row.from
                        } else {
                            let disabled = true;
                            selectedRows.forEach(selectedRow => {
                                if (selectedRow.id === row.id) disabled = false
                            });

                            let customHistory = null;
                            if (!isNull(history) && hasHistory) {
                                data.value?.forEach(val => {
                                    if (val.id === row.id) {
                                        if (history.field.value.indexOf(val.id) !== -1) {
                                            const historyValue = history.field.values.find(value => value.id === row.id);
                                            if (historyValue) {
                                                customHistory = {
                                                    field: {value: historyValue.from},
                                                    changed_by: history.changed_by,
                                                    changed_at: history.changed_at,
                                                };
                                            }
                                        }
                                    }
                                })
                            }

                            return <TimeInput data={{name: "from", disabled: disabled, value: row.from }} onChange={(e, name) => handleRowInput(e, name, row)} history={customHistory} />;
                        }
                    }
                },
                {
                    name: t('table.to'),
                    selector: row => row.to,
                    cell: row => {
                        if (data.disabled) {
                            return row.to
                        } else {
                            let disabled = true;
                            selectedRows.forEach(selectedRow => {
                                if (selectedRow.id === row.id) disabled = false
                            });

                            let customHistory = null;
                            if (!isNull(history) && hasHistory) {
                                data.value?.forEach(val => {
                                    if (val.id === row.id) {
                                        if (history.field.value.indexOf(val.id) !== -1) {
                                            const historyValue = history.field.values.find(value => value.id === row.id);
                                            if (historyValue) {
                                                customHistory = {
                                                    field: {value: historyValue.to},
                                                    changed_by: history.changed_by,
                                                    changed_at: history.changed_at,
                                                };
                                            }
                                        }
                                    }
                                })
                            }

                            return <TimeInput data={{name: "to", disabled: disabled, value: row.to }} onChange={(e, name) => handleRowInput(e, name, row)} history={customHistory} />;
                        }
                    }
                }
            )
        }

        if (!data.disabled) {
            columns.unshift(
                {
                    name: '',
                    width: '50px',
                    cell: row => {
                        let isSelected = false;
                        selectedRows.forEach(selectedRow => {
                            if (selectedRow.id === row.id) isSelected = true;
                        });

                        let disableCheckBox = false;
                        if (type === 'create' && token.parsedToken.id === row.id) disableCheckBox = true;

                        // History
                        let changed = false;
                        if (!isNull(history) && hasHistory) {
                            data.value?.forEach(val => {
                                if (val.id === row.id) {
                                    if (history.field.value.indexOf(val.id) === -1) {
                                        changed = true;
                                    }
                                }
                            });

                            if (!changed) {
                                if (history.field.value.indexOf(row.id) !== -1) {
                                    let isInValues = false;
                                    for (let i = 0; i < data.value.length; i++) {
                                        if (data.value[i].id === row.id) {
                                            isInValues = true;
                                        }
                                    }

                                    if (!isInValues) changed = true;
                                }
                            }
                        }

                        return (
                            <div className={changed ? "history-row-changed" : ""}>
                                <div className="custom-control custom-checkbox form-check">
                                    <div className="form-checkbox-group">
                                        <Input
                                            className={"custom-control-input"}
                                            id={row.id + '-' + saveToLS}
                                            type="checkbox"
                                            onChange={() => handleRowBool(row, 'select')}
                                            checked={isSelected}
                                            disabled={disableCheckBox}
                                            />
                                        <Label className={"checkmark secondary"} htmlFor={row.id + '-' + saveToLS}></Label>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                },
            )
        }

        return columns
    }, [selectedRows, data.disabled, history, quotationPricing]) // eslint-disable-line react-hooks/exhaustive-deps

    // Row handlers
    const handleRowBool = (row, type) => {
        let clonedSelectedRows = [...selectedRows];
        if (type === 'select') {
            let isInside = clonedSelectedRows.find(selected => selected.id === row.id);

            if (isNull(isInside)) {
                clonedSelectedRows.forEach(selected => {
                    if (selected.id === token.parsedToken.id) {
                        row.from = selected.from;
                        row.to = selected.to;
                    };
                })
                clonedSelectedRows.push(row)
                setSelectedRows(clonedSelectedRows)
                localStorage.setItem(saveToLS, JSON.stringify(clonedSelectedRows))
            } else {
                let filtered = clonedSelectedRows.filter(selected => selected.id !== row.id)
                setSelectedRows(filtered)
                localStorage.setItem(saveToLS, JSON.stringify(filtered))
            }
        } else if (type === 'programming_work' || type === 'remote_work') {
            clonedSelectedRows.forEach(selected => {
                if (selected.id === row.id) {
                    selected[type] = !selected[type];
                };
            })

            setSelectedRows(clonedSelectedRows)
            localStorage.setItem(saveToLS, JSON.stringify(clonedSelectedRows))
        }

        getData();
    }

    const handleRowInput = (e, name, row) => {
        let clonedSelectedRows = [...selectedRows]
        clonedSelectedRows.forEach(selected => {
            if (selected.id === row.id) {
                selected[name] = e.target.value

                // Show warning if from > to
                if (selected.from > selected.to) {
                    Swal.fire({
                        title: t('warning'),
                        text: t('service.fromToWarning'),
                        icon: 'warning',
                        confirmButtonColor: 'var(--theme-default)',
                        confirmButtonText: t('ok'),
                    })
                }
            }
        })

        setSelectedRows(clonedSelectedRows);
        localStorage.setItem(saveToLS, JSON.stringify(clonedSelectedRows))
        getData(); // Call BE to get work_log
    }
    // End row handlers

    // Filter technicians for CreateService component
    const filteredTableData = useMemo(() => {
        if (showAll || type !== 'create') return tableData

        let filtered = tableData.filter(row => {
            let isSelected = false;
            selectedRows.forEach(selected => {
                if (selected.id === row.id) isSelected = true;
            });

            return isSelected;
        });

        return filtered

    }, [JSON.stringify(tableData), showAll]) // eslint-disable-line react-hooks/exhaustive-deps

    if (data.display) {
        return (
            <FormGroup>
                <Label>{data.title + (data.mandatory ? " *" : "")}</Label>
                {tableData.length !== 0 ?
                    <DataTable
                        columns={tableColumns}
                        data={filteredTableData}
                        pagination
                        paginationComponentOptions={paginationComponentOptions(t)}
                        customStyles={customStyles}
                    />
                    :
                    <div>{t('table.noTechnicians')}</div>
                }
                {!isNull(showErrors) && showErrors && !isNull(data.errors) && data.errors.length !== 0 &&
                    data.errors.map((error, index) => (
                        <div key={'material-error-'+index} style={{ color: "red", marginBottom: "10px" }}>{error}</div>
                    ))
                }
                {hasHistory && <DisplayHistory history={history} />}
                {type === 'create' && <Button color="secondary" onClick={() => setShowAll(!showAll)}>{showAll ? t('table.tech.hideUnselected') : t('table.tech.showAll')}</Button>}
            </FormGroup>
        )
    } else {
        return null;
    }

}

export default TableField